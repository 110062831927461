import PropTypes from 'prop-types'
import styles from './Hero.module.scss'

import Image from 'basics/Image'
import Heading from 'basics/Heading'
import Container from 'containers/Container'
import SingleColumn from 'layouts/SingleColumn'
import Spacer from 'basics/Spacer'
import { ClassNames } from 'services/classname.service'
// import { FormatSuperscript } from 'services/utils'
import Breadcrumbs from './Breadcrumbs'
import Tags from 'components/Tags/Tags'
import CtaWidgets from '../../../../features/CtaWidgets/CtaWidgets'

function Hero({
  className,
  title,
  content,
  breadcrumbs,
  children,
  data = {},
  pathData = {},
  backgroundImage
}) {
  const { headline, subheadline, description, ctaWidgets, tags, width = 'normal', view } = data

  return (
    <section
      className={ClassNames([
        styles[`ds-hero`],
        styles[`ds-hero__${width}`],
        backgroundImage ? styles['ds-hero--with-background'] : null,
        className
      ])}
    >
      {backgroundImage && (
        <div className={styles['ds-hero__background']}>
          <Image className={styles['ds-hero__background-image']} src={backgroundImage?.fileName} />
        </div>
      )}
      <Container>
        <SingleColumn className="text-align-center">
          {breadcrumbs && <Breadcrumbs path={breadcrumbs || {}} pathData={pathData} />}
          {headline && (
            <Heading level={1} className="mx-auto">
              {headline}
              {/* {Parse(FormatSuperscript(headline))} */}
            </Heading>
          )}
          <Spacer size={view==='resource' ? 3 : 5} />
          {children && <div className={styles['ds-hero__intro']}>{children}</div>}
          {subheadline && (
            <div className={styles['ds-hero__intro']}>
              <div className={ClassNames(['intro-paragraph', backgroundImage ? 'dark' : null, view==='resource' ? 'is-resource' : ''])} dangerouslySetInnerHTML={{ __html: subheadline }} />
            </div>
          )}
          {description && (
            <div className={styles['ds-hero__intro']}>
              <div className="intro-paragraph" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}

          {tags && <Tags tags={tags} />}

          {ctaWidgets && <CtaWidgets ctaWidgets={ctaWidgets} />}
        </SingleColumn>
      </Container>
    </section>
  )
}

Hero.propTypes = {
  title: PropTypes.string
}

export default Hero
